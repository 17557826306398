import { DentistaService } from './../../../dentista.service';
import { Dentista } from './../dentista.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dentista-criar',
  templateUrl: './dentista-criar.component.html',
  styleUrls: ['./dentista-criar.component.css']
})
export class DentistaCriarComponent implements OnInit {
  dentista:Dentista ={
    id    : 0,
    crosp :'' ,
    cnpj : '',
    nome : '' ,
    endereco: '',
    numero : null,
    Bairro : '',
    email  : '',
    telefone : '',
    whatsapp : '',
}
  constructor(private router:Router, 
              private dentistaService: DentistaService) { }

  ngOnInit(): void {
  }



  retornarDentista():void{
    this.router.navigate(['dentista'])
  }

  criarDentista():void{
    this.dentistaService.addDentista(this.dentista)
    this.router.navigate(['dentista'])
    this.dentistaService.showMessage("cadastrado com Sucesso",false)
  }
}
