import { MatSnackBar } from '@angular/material/snack-bar';
import { Dentista } from './cadastro/dentista/dentista.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DentistaService {

  constructor(private snackBar: MatSnackBar) {

    this.load()
  }





  getDentistas(): Observable<Dentista[]> {
    const dentistas = JSON.parse(localStorage.getItem('dentistas'));
    return of(dentistas);
  }



  addDentista(denstisa: Dentista) {

    let id = + localStorage.getItem('id_dentista') + 1;

    denstisa.id = id;


    let a = [] = JSON.parse(localStorage.getItem('dentistas'));


    a.push(denstisa);
    localStorage.setItem('dentistas', JSON.stringify(a));
    localStorage.setItem('id_dentista', id.toString());
  }


  deleteDentista(id) {
    let emps = JSON.parse(localStorage.getItem('dentistas'));
    for (let i = 0; i < emps.length; i++) {
      if (emps[i].id == id) {
        emps.splice(i, 1);
      }
    }
    localStorage.setItem('dentistas', JSON.stringify(emps));
  }


  getDentista(id) {
    let emps = JSON.parse(localStorage.getItem('dentistas'));
    let emp;
    for (let i = 0; i < emps.length; i++) {
      if (emps[i].id == id) {
        emp = emps[i]
      }
    }
    console.log(emp)

    return emp;
  }

  updateDentista(denstisa: Dentista) {

    let emps = JSON.parse(localStorage.getItem('dentistas'));

    for (let i = 0; i < emps.length; i++) {
      if (emps[i].id == denstisa.id) {
        emps[i] = denstisa;
      }
    }
    localStorage.setItem('dentistas', JSON.stringify(emps));
  }


  load() {
    if (localStorage.getItem('dentistas') === null || localStorage.getItem('dentistas') == undefined) {
      console.log('No employees Found... Creating...');
      let emps = [

        {
          id: 1,
          crosp: '123',
          cnpj: '1234564678',
          nome: 'DENTISTA TESTE',
          endereco: 'RUA BRASIL',
          numero: 123,
          Bairro: 'SP',
          email: 'dentista@123.com',
          telefone: '123',
          whatsapp: '123'
        },


        {
          id: 1,
          crosp: '123',
          cnpj: '1234564678',
          nome: 'DENTISTA TESTE',
          endereco: 'RUA BRASIL',
          numero: 123,
          Bairro: 'SP',
          email: 'dentista@123.com',
          telefone: '123',
          whatsapp: '123'
        },


        {
          id: 2,
          crosp: '123',
          cnpj: '1234564678',
          nome: 'DENTISTA TESTE',
          endereco: 'RUA BRASIL',
          numero: 123,
          Bairro: 'SP',
          email: 'dentista@123.com',
          telefone: '123',
          whatsapp: '123'
        },


        {
          id: 3,
          crosp: '123',
          cnpj: '1234564678',
          nome: 'DENTISTA TESTE',
          endereco: 'RUA BRASIL',
          numero: 123,
          Bairro: 'SP',
          email: 'dentista@123.com',
          telefone: '123',
          whatsapp: '123'
        },


        {
          id: 4,
          crosp: '123',
          cnpj: '1234564678',
          nome: 'DENTISTA TESTE',
          endereco: 'RUA BRASIL',
          numero: 123,
          Bairro: 'SP',
          email: 'dentista@123.com',
          telefone: '123',
          whatsapp: '123'
        },

        {
          id: 5,
          crosp: '123',
          cnpj: '1234564678',
          nome: 'DENTISTA TESTE',
          endereco: 'RUA BRASIL',
          numero: 123,
          Bairro: 'SP',
          email: 'dentista@123.com',
          telefone: '123',
          whatsapp: '123'
        },

      ];

      localStorage.setItem('dentistas', JSON.stringify(emps));
      localStorage.setItem('id_dentista', "5");
      return
    } else {
      console.log('Found employees...');
    }
  }



  showMessage(msg: string, isError: boolean): void {

    this.snackBar.open(msg, 'X', {
      duration: 300000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: isError ? ['msg-error'] : ['msg-success']
    })

  }

}
