import { DentistaUpdateComponent } from './view/cadastro/dentista/dentista-update/dentista-update.component';
import { DentistaCriarComponent } from './view/cadastro/dentista/dentista-criar/dentista-criar.component';
import { DentistaListComponent } from './view/cadastro/dentista/dentista-list/dentista-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'dentista',
    component: DentistaListComponent
  },

  {
    path: 'dentista/novo',
    component: DentistaCriarComponent
  },
  {
    path: 'dentista/editar/:id',
    component: DentistaUpdateComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
