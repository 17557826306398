import { Observable } from 'rxjs';
import { DentistaService } from './../../../dentista.service';
import { Dentista } from './../dentista.model';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-dentista-list',
  templateUrl: './dentista-list.component.html',
  styleUrls: ['./dentista-list.component.css']
})




export class DentistaListComponent implements OnInit {


  

  //de: Dentista[];

  
  dataSource: MatTableDataSource<Dentista> 


  displayedColumns = ['id', 'crosp','cnpj', 'nome', 'telefone', 'whatsapp','action'];

  totalElements: number;

  constructor(private router:Router,
              private dentistaService: DentistaService) { }

  
  
  ngOnInit(): void {

    this.listarDentista();


  }

  listarDentista(){
   this.dentistaService.getDentistas().subscribe(
     (dentista: Dentista[])=>{
      this.dataSource = new MatTableDataSource<Dentista>(dentista);

      console.log(this.dataSource)
     },
      error=> console.log(error)
   )
   
   
  }
  novoDentista(){
    this.router.navigate(['dentista/novo'])
  }


  onPageEvent(event: PageEvent){

  }


  deleteDentista(id: number){
     let cod = confirm('deseja realmente remover?')

      if(cod == true){
         this.dentistaService.deleteDentista(id); 
        this.listarDentista();

        this.dentistaService.showMessage("Dentista removido com Sucesso", true)
      }
  }

}
