<mat-sidenav-container class="container">
  <mat-sidenav class="sidenav" mode="side" 
                               opened 
                               fixedInViewport="true" 
                               fixedTopGap="54" 
                               fixedBottomGap="32">
    <mat-nav-list>
      <a mat-list-item routerLink="/">
        <i class="material-icons">home</i>Home
      </a>
      <!-- <a mat-list-item routerLink="/calendario">
        <i class="material-icons">calendar_today</i>Calendário
      </a>

      <a mat-list-item routerLink="/certificado">
        <i class="material-icons">receipt_long
        </i>Certificado
      </a> -->
  
      <a class="" mat-list-item  [matMenuTriggerFor]="menu"> <i class="material-icons">add</i>Cadastrar</a>
      <mat-menu #menu="matMenu">
        <a mat-menu-item routerLink="/dentista">Dentista</a>
        
      </mat-menu>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>