import { Dentista } from './../dentista.model';
import { DentistaService } from './../../../dentista.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dentista-update',
  templateUrl: './dentista-update.component.html',
  styleUrls: ['./dentista-update.component.css']
})
export class DentistaUpdateComponent implements OnInit {

  
  dentista:Dentista ={
    id    : 0,
    crosp :'' ,
    cnpj : '',
    nome : '' ,
    endereco: '',
    numero : null,
    Bairro : '',
    email  : '',
    telefone : '',
    whatsapp : '',
}


  constructor(private router:Router, 
              private  route:ActivatedRoute,
              private dentistaService: DentistaService) { }

  ngOnInit(): void {
    this.get()
  }


  get(){
     const id = + this.route.snapshot.paramMap.get("id");
    
     this.dentista = this.dentistaService.getDentista(id);
  }

  editarDentista():void{
    this.dentistaService.updateDentista(this.dentista);
    this.router.navigate(['dentista'])
  }

  retornarDentista():void{
    this.router.navigate(['dentista'])
  }

  

}
