<div class="dentista">

  <button mat-flat-button color="primary" class="btn-add-smbb" (click)="novoDentista()">
    <i class="material-icons">add</i> Cadastrar
  </button>

  <h1>DENTISTA</h1>
  
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z2">


    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  > # </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="crosp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  > CROSP </th>
      <td mat-cell *matCellDef="let element"> {{element.crosp}} </td>
    </ng-container>


    <ng-container matColumnDef="cnpj">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  >CNPJ </th>
      <td mat-cell *matCellDef="let element"> {{element.cnpj}} </td>
    </ng-container>


    <ng-container matColumnDef="nome">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  > NOME </th>
      <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
    </ng-container>


    <ng-container matColumnDef="telefone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  > TELEFONE </th>
      <td mat-cell *matCellDef="let element"> {{element.telefone}} </td>
    </ng-container>

    
    <ng-container matColumnDef="whatsapp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header  > Whatsapp </th>
      <td mat-cell *matCellDef="let element"> {{element.whatsapp }} </td>
    </ng-container>



   

    <!-- Action Column -->

     <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef >Ações</th>
      <td mat-cell *matCellDef="let row">
        <a routerLink="/dentista/editar/{{row.id}}" class="edit">
          <i class="material-icons">
            edit
          </i>
        </a>
        <a  (click)="deleteDentista(row.id)" class="delete">
          <i class="material-icons">
            delete
          </i>
        </a>

      </td>
    </ng-container> 

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <!-- <mat-paginator [length]="this.totalElements" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"
  (page)="onPageEvent($event)">
 </mat-paginator> -->
  
</div>


