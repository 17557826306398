import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DentistaListComponent } from './view/cadastro/dentista/dentista-list/dentista-list.component';
import { DentistaUpdateComponent } from './view/cadastro/dentista/dentista-update/dentista-update.component';
import { DentistaCriarComponent } from './view/cadastro/dentista/dentista-criar/dentista-criar.component';
import { HeaderComponent } from './template/header/header.component';
import { NavComponent } from './template/nav/nav.component';
import { FooterComponent } from './template/footer/footer.component';
import { FormsModule }   from '@angular/forms';  // Adicionei aqui


// materuak  
import { MatToolbarModule }  from '@angular/material/toolbar';
import { MatSidenavModule }  from '@angular/material/sidenav';
import { MatListModule }   from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatTableModule} from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    AppComponent,
    DentistaListComponent,
    DentistaUpdateComponent,
    DentistaCriarComponent,
    HeaderComponent,
    NavComponent,
    FooterComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatSnackBarModule,
    MatSnackBarModule,
    MatTableModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    MatInputModule,
    FormsModule

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
