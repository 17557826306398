

<div class="novo-dentista">

  <mat-card>
    <mat-card-title> NOVO DENTISTA </mat-card-title>
    <form class="example-form">
      <mat-form-field  appearance="legacy" class="example-full-width">
        <mat-label>CROSP</mat-label>
        <input matInput [(ngModel)]="dentista.crosp" name="crosp" >
      </mat-form-field>

      <mat-form-field appearance="legacy" class="example-full-width">
        <mat-label>CNPJ</mat-label>
        <input matInput [(ngModel)]="dentista.cnpj" name="cnpj" >
      </mat-form-field>
    
      <mat-form-field appearance="legacy" appearance="legacy" class="example-full-width">
        <mat-label>NOME </mat-label>
        <input matInput [(ngModel)]="dentista.nome" name="nome" >
      </mat-form-field>

      <mat-form-field appearance="legacy" class="example-full-width">
        <mat-label>ENDEREÇO</mat-label>
        <input matInput [(ngModel)]="dentista.endereco" name="endereco" >
      </mat-form-field>
    
      <mat-form-field appearance="legacy" class="example-full-width">
        <mat-label>Nº</mat-label>
        <input  matInput [(ngModel)]="dentista.numero" name="numero" >
      </mat-form-field> 

      <mat-form-field appearance="legacy" class="example-full-width">
        <mat-label>E-MAIL</mat-label>
        <input  matInput [(ngModel)]="dentista.email" name="email" >
      </mat-form-field> 

       <mat-form-field appearance="legacy" class="example-full-width">
        <mat-label>TELEFONE</mat-label>
        <input  matInput [(ngModel)]="dentista.telefone" name="telefone" >
      </mat-form-field> 

       <mat-form-field appearance="legacy" class="example-full-width">
        <mat-label>WHATSAPP</mat-label>
        <input  matInput [(ngModel)]="dentista.whatsapp" name="whatsapp" >
      </mat-form-field> 


      <div class="btn-form"> 

        <button mat-raised-button color="primary" (click)="criarDentista()" ><i class="material-icons">save</i> Salvar</button>
        <button mat-raised-button color="warn" (click)="retornarDentista()"><i class="material-icons">reply</i> Voltar</button>

      </div> 

    </form>
    </mat-card>
</div>

